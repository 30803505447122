<template>
  <header class="dejia-header d-flex flex-row align-items-center fw-bold">
    <div class="logo">
      <img src="@/assets/images/schools/dejia/dejia-logo.png" />
    </div>
    <div>一站式综合素质发展基地</div>
  </header>
</template>

<style scoped>
.dejia-header {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  padding: 0 46px;
  color: white;
  font-size: 28px;
}
.dejia-header .logo {
  height: 100%;
  margin-right: 30px;
  overflow: hidden;
}
.dejia-header .logo img {
  width: 260px;
}
</style>
